import en from '../locales/en.json'

export * from './AutocompleteResult'
export * from './BodyZone'
export * from './CheckPhaseStep'
export * from './CheckStateInfo'
export * from './CheckType'
export * from './ClusterPoint'
export * from './ContentLibraryIllnessDetails'
export * from './CustomTranslation'
export * from './Features'
export * from './GenericSearchParameters'
export * from './Imprint'
export * from './Overview'
export * from './Question'
export * from './ResponseType'
export * from './Service'
export * from './Translation'
export * from './WebAppConfig'
export * from './Matomo'
export * from './Datadog'

export type TranslationCodeLiteral = keyof typeof en
export const translationCodes = Object.keys(en) as TranslationCodeLiteral[]
